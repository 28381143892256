<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card no-body class="mb-0">
      <b-row class="m-2 pt-2">
        <b-col cols="12" md="3">
          <div class="d-flex flex-column align-items-center">
            <b-avatar
              rounded="bottom"
              variant="light-dark"
              size="168"
              :src="
                previewLogo ? previewLogo : constructUrlImage(userData.logo)
              "
            />
            <b-button
              variant="primary"
              type="submit"
              class="mt-1"
              @click="$refs.refInputEl.click()"
            >
              <input
                ref="refInputEl"
                type="file"
                class="d-none"
                @input="inputImage"
              />
              {{ $t("upload_image") }}
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" md="8">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <!-- Company name -->
              <validation-provider
                #default="validationContext"
                :name="$t('company_name')"
                rules="required"
              >
                <b-form-group
                  :label="$t('company_name')"
                  label-for="company-name"
                >
                  <b-form-input
                    id="company-name"
                    v-model="userData.name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Mobile no -->
              <validation-provider
                #default="validationContext"
                :name="$t('mobile_no')"
                rules="required|integer"
              >
                <b-form-group :label="$t('mobile_no')" label-for="mobile-no">
                  <b-form-input
                    id="mobile-no"
                    v-model="userData.mobileNo"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Email -->
              <b-form-group :label="$t('email')" label-for="email">
                <b-form-input id="email" v-model="userData.email" disabled />
              </b-form-group>

              <!-- Username -->
              <b-form-group :label="$t('username')" label-for="username">
                <b-form-input id="username" v-model="userData.username" />
              </b-form-group>

              <!-- Address -->
              <validation-provider
                #default="validationContext"
                name="Address"
                rules="required"
              >
                <b-form-group :label="$t('address')" label-for="address">
                  <b-form-textarea
                    id="address"
                    v-model="userData.address"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <div class="form-group">
                <label for="wildcard">{{ $t("upload_commercial") }}</label>
                <b-form-file
                  v-model="uploadCommercialFile"
                  :placeholder="
                    userData.commercialFile
                      ? userData.commercialFile.fileName
                      : 'อัพโหลดไฟล์'
                  "
                  drop-placeholder="Drop file here..."
                  no-drop
                />
              </div>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button variant="primary" class="mr-2" type="submit">
                  {{ $t("save") }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BFormInvalidFeedback,
  BFormTextarea,
  BAvatar,
  BImg,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import { required, integer, email } from "@validations";
import { companyService } from "@/services/company";
import { uploadService } from "@/services/upload";
import { constructUrlImage } from "@/libs/helper";
export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BFormInvalidFeedback,
    BFormTextarea,
    BLink,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const toast = useToast();
    const userData = ref(props.userData);
    const logoFile = ref(null);
    const loading = ref(false);
    const previewLogo = ref(null);
    const uploadCommercialFile = ref(null);

    const blankUserData = {
      companyName: "",
      address: "",
      mobileNo: "",
      logo: "",
      email: "",
      username: "",
      password: "",
      commercialFile: null,
    };

    const resetUserData = () => {
      userData.value = { ...blankUserData };
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetUserData);

    const inputImage = (e) => {
      logoFile.value = e.target.files[0];
      previewLogo.value = URL.createObjectURL(logoFile.value);
    };

    const onSubmit = async () => {
      loading.value = true;
      const updateData = { ...userData.value };

      delete updateData.commercialFile;
      delete updateData.logo;
      try {
        //check file if file not null then call api to store file and get file to userData
        if (uploadCommercialFile.value) {
          const formData = new FormData();
          formData.append(
            "file",
            uploadCommercialFile.value,
            uploadCommercialFile.value.name
          );
          formData.append("fileType", "commercial");
          const respCommercialFile = await uploadService.uploadFile(formData);
          updateData.commercialFile = respCommercialFile.data._id;
        }

        if (logoFile.value) {
          const formDataLogo = new FormData();
          formDataLogo.append("file", logoFile.value, logoFile.value.name);
          formDataLogo.append("fileType", "logo");
          const respLogoFile = await uploadService.uploadFile(formDataLogo);
          updateData.logo = respLogoFile.data._id;
        }

        let response = await companyService.updateData(
          updateData,
          updateData._id
        );

        if (response.status) {
          loading.value = false;
          localStorage.setItem("userData", JSON.stringify(updateData));
          toast({
            component: ToastificationContent,
            props: {
              title: "Manage company success",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      } catch (error) {
        console.log(error);
        loading.value = false;
        toast({
          component: ToastificationContent,
          props: {
            title: "Manage company failed",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    };

    return {
      inputImage,
      previewLogo,

      required,
      integer,
      email,

      getValidationState,
      refFormObserver,
      uploadCommercialFile,

      onSubmit,
      constructUrlImage,
      loading,
    };
  },
};
</script>
