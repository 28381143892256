<template>
  <b-card :title="$t('department')">
    <table class="options table table-bordered table-striped">
      <thead>
        <tr>
          <th>ลำดับ</th>
          <th>{{ $t("department") }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody id="option-0-select-values" class="sortable">
        <tr
          class="option-row"
          v-for="(department, index) in departmentList"
          :key="index"
        >
          <td class="text-center">
            <span>{{ index + 1 }}</span>
          </td>

          <td class="text-center">
            <b-form-input v-model="department.name" />
          </td>
          <td class="text-center">
            <feather-icon
              @click="removeDepartment(index)"
              v-show="index !== 0"
              icon="Trash2Icon"
              svgClasses="w-5 h-5 hover:text-danger stroke-current"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <b-button type="submit" variant="primary" class="mt-2" @click="onSubmit()">
      {{ $t("save") }}
    </b-button>
    <b-button
      type="submit"
      variant="danger"
      @click="newDepartment()"
      class="mt-2 ml-1"
    >
      {{ $t("add_department") }}
    </b-button>
  </b-card>
</template>
<script>
import { BCard, BFormInput, BRow, BCol, BButton } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { onMounted, ref } from "@vue/composition-api";
import { departmentService } from "@/services/department";
import { decodeToken } from "@/@core/utils/decodeJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    AppCollapse,
    AppCollapseItem,
  },
  setup() {
    const toast = useToast();

    let departmentList = ref([
      {
        name: "",
      },
    ]);

    let deleteItem = [];
    const loading = ref(false);

    const currentRole = decodeToken();

    onMounted(() => {
      departmentService
        .fetchData(currentRole.id)
        .then((res) => {
          departmentList.value = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    });
    const newDepartment = () => {
      departmentList.value.push({
        name: "",
      });
    };

    const removeDepartment = (index) => {
      if (departmentList.value[index]._id) {
        deleteItem.push(departmentList.value[index]._id);
      }
      departmentList.value.splice(index, 1);
    };

    const onSubmit = async () => {
      const requestData = {
        create: departmentList.value.filter((item) => !item._id),
        delete: deleteItem,
        update: departmentList.value
          .filter((item) => item._id)
          .map((item) => ({
            _id: item._id,
            name: item.name,
          })),
      };

      try {
        const response = await departmentService.createData(requestData);
        if (response.status) {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Manage department success",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      } catch (error) {
        console.log(error);
        loading.value = false;
        toast({
          component: ToastificationContent,
          props: {
            title: "Manage department failed",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    };

    return {
      departmentList,
      newDepartment,
      removeDepartment,

      onSubmit,
    };
  },
};
</script>
<style scoped>
.table {
  width: 100%;
  border-collapse: collapse;
}
.options thead th:first-child {
  width: 40px;
}
.table > thead > tr > th {
  font-weight: 600;
  border: none;
  border-bottom: 1px solid #e9e9e9;
  font-size: 12px;
}
.table-bordered,
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid #ddd;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 8px;
}
.options thead th:last-child,
.options thead th:first-child {
  width: 60px;
}
.options thead th:first-child {
  width: 40px;
}
.show-image {
  height: 85px;
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  cursor: pointer;
}
</style>
